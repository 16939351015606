<template>
    <div class="user-line" :class="[className || '']">
        <input :type="type" 
            :class="[inputError ? 'error' : '']"
            :name="name"
            :value="inputValue"
            :disabled="disabled"
            @focusin="shiftSpan" 
            @focusout="unshiftSpan" 
            @change="changeInput"
            @keyup="changeInput">
        <span :class="{'shifted': isShifted}">{{label}}</span>            
    </div>
</template>
<script>
export default {
    data() {
        return {
            isShifted: false
        }
    },
    props: ['type', 'name', 'label', 'reference', 'inputError', 'inputValue', 'className', 'disabled'],
    methods: {        
        shiftSpan: function() {
            this.isShifted = true;
        },
        unshiftSpan: function(e) {
            if (e.target.value.trim() === "") {
                this.isShifted = false;
            }     
        },
        changeInput: function(e) {
            if (this.name === "password") {
                this.$emit('setPassword', e.target.value);
            } else if (this.name === "repeat-password") {
                this.$emit('setPasswordRepeat', e.target.value);
            } else if (this.name === "email") {
                this.$emit('setEmail', e.target.value)
            } else if (this.name === "name") {
                this.$emit('setName', e.target.value)
            } else if (this.name === "phone") {
                this.$emit('setPhone', e.target.value)
            } else if (this.name === "number") {
                this.$emit('setNumber', e.target.value)
            } else if (this.name === "price") {
                this.$emit('setPrice', e.target.value)
            } else if (this.name === "second-name") {
                this.$emit('setSecondName', e.target.value)
            } else if (this.name === "phone-code") {
                this.$emit('setPhoneCode', e.target.value)
            } else if (this.name === "post-code") {
                this.$emit('setPostCode', e.target.value)
            } else if (this.name === "address") {
                this.$emit('setAddress', e.target.value)
            } else if (this.name === "state") {
                this.$emit('setState', e.target.value)
            } else if (this.name === "city") {
                this.$emit('setCity', e.target.value)
            }
        }
    },
    mounted() {
        if (this.inputValue) {            
            if (this.inputValue.trim() !== "") {
                this.shiftSpan()
            }
        }
    }
}
</script>